<template>
  <div class="page">
    <div class="title">
      <div>
        <span>测评记录ID</span>
        <el-input class="input-width" size="small" v-model="state.id" placeholder="请输入测评ID" />
      </div>
      <div>
        <span>测评名称</span>
        <el-input class="input-width" size="small" v-model="state.name" placeholder="模糊匹配" />
      </div>
      <div>
        <span>渠道ID</span>
        <el-input class="input-width" size="small" v-model="state.channelId" placeholder="请输入渠道ID" />
      </div>
      <div>
        <span>测评时间</span>
        <el-date-picker class="input-width"
         size="small"
          v-model="state.time"
          type="date"
          placeholder="请选择时间"
        >
        </el-date-picker>
      </div>
      <div class="end-box">
        <el-button size="small" type="primary" @click="find"
          >查询</el-button>
        <el-button size="small" @click="init">重置</el-button>
        <!-- <el-button size="small" @click="exportExcel">导出</el-button> -->
      </div>
    </div>
    <div class="main">
      <p style="margin-bottom:10px;">当前渠道: <span style="color: #71a8ff;"> {{ state.currentChannel || "全渠道"}}</span></p>
      <el-table :data="state.list" style="width: 100%" size="small" :header-cell-style="{background:'#f7fbff',color:'#000000d9'}" border highlight-current-row>
        <el-table-column prop="id" label="评测记录ID"  />
        <el-table-column prop="createBy" label="渠道号"  />
        <el-table-column prop="title" label="评测名称"  />
        <el-table-column label="评测时间" >
          <template v-slot="scope">
            {{ scope.row.gmtCreate }}
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="评测人"  />
        <el-table-column prop="score" label="评测总得分"  />

        <el-table-column label="状态" >
          <template v-slot="scope">
            <span v-if="scope.row.status == 1" style="color: #fc9100"
              >待支付</span
            >
            <span v-if="scope.row.status == 2" style="color: #ff554f"
              >已支付待测评</span
            >
            <span v-if="scope.row.status == 3">已评测未生成报告</span>
            <span v-if="scope.row.status == 5">已生成报告</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" >
          <template v-slot="scope">
            <el-button
              type="text"
              :style="{ color: '#000000d9' }"
              @click="look(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-model:currentPage="state.currentPage"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="state.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="state.total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
    </el-pagination>
    </div>
      <el-drawer
      v-model="state.drawer"
      title="测评详情"
      direction="rtl"
      :before-close="handleClose"
    >
    <div class="drawer-div">
      <p class="drawer-p">测评ID: <span>{{state.checkedInfo.id}}</span></p>
      <p class="drawer-p">测评名: <span>{{state.checkedInfo.title}}</span></p>
      <p class="drawer-p">测评总得分: <span>{{state.checkedInfo.score}}</span></p>
      <p class="drawer-p">测评总等级: <span>{{state.remark}}</span></p>
      <el-table :data="state.checkedInfoList" style="width: 100%;margin-top:10px;" size="small" :header-cell-style="{background:'#f7fbff',color:'#000000d9'}" border highlight-current-row>
        <el-table-column prop="title" label="分维度"  />
        <el-table-column prop="score" label="分维度得分"  />
        <el-table-column prop="description" label="分维度概述" />
      </el-table>
    </div>
      


    </el-drawer>
  </div>
</template>

<script>
import { reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ExportJsonExcel from 'js-export-excel'
import { CODE } from "@/config"
export default {
  setup() {
    const store = useStore();
    const getData = (v) => {
      let data
      if (v) {
        data = v
      }else{
        data = {
          page: state.page,
          size: state.size,
        };
      }
      
      store.dispatch("record/orderInfoList", data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          console.log(res.data);
          state.list = res.data.values;
          if (res.data.total) {
            state.total = res.data.total * 1;
          }else{
            state.total = 0
          }
          state.channelId && (state.currentChannel = state.channelId)
          !state.channelId && (state.currentChannel = "")
        }
      });
    };
    onBeforeMount(() => {
        getData();
    });
    const state = reactive({
      id: "",
      name: "",
      time: "",
      channelId: "",
      currentChannel:"",
      list: [],
      page: 1,
      size: 10,
      total: 0,
      currentPage:1,
      drawer:false,
      checkedInfo:{},
      remake:'',
      checkedInfoList:[]
    });
    const init = () => {
      state.id = "";
      state.name = "";
      state.time = "";
      state.channelId = "";
      // state.currentChannel = "";
    };
    const find = (i) => {
      if (i != 1) {
        state.page = 1
        state.currentPage = 1
      }
      const { id, name, time, channelId, page, size } = state;
      let data = {
        page,
        size,
      };
      if(id) data.id = id
      if(name) data.title = name
      if(time) data.gmtCreate = formatDate2(time)
      if(channelId) data.channelId = channelId
      console.log(data);
      getData(data)
    };
    const look = (item) => {
      state.checkedInfo = {}
      state.checkedInfoList = []
      state.checkedInfo = item
      state.drawer = true
      let data = {
        id:item.id
      }
      console.log(item);
      store.dispatch("record/orderInfoListOption",data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          state.remark = res.data.remark
          state.checkedInfoList = res.data.scaleItems
        }
      });
    };
    const handleCurrentChange = (v) => {
      state.page = v;
      find(1)
    };
    const handleSizeChange = (val) => {
      state.size = val
      getData();
      console.log(val)
    }
    const handleClose = (done) => {
      done()
    }
    const formatDate = function (value, spe = "-") {
      let data = new Date(value * 1);
      let year = data.getFullYear();
      let month = data.getMonth() + 1;
      let day = data.getDate();
      let h = data.getHours();
      let mm = data.getMinutes();
      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      h = h >= 10 ? h : "0" + h;
      mm = mm >= 10 ? mm : "0" + mm;
      return `${year}${spe}${month}${spe}${day} ${h}:${mm}`;
    };
    const formatDate2 = function (value, spe = "-") {
      if (!value) {
        return;
      }
      let data = new Date(value * 1);
      let year = data.getFullYear();
      let month = data.getMonth() + 1;
      let day = data.getDate();
      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      return `${year}${spe}${month}${spe}${day}`;
    };
    const exportExcel = () =>{
      let option = {};
 
      option.fileName = "excel1";
      
      option.datas = [
        {
          sheetData: [
            { one: "一行一列", two: "一行二列", three: "一行三列" },
            { one: "二行一列", two: "二行二列" },
            { one: "三行一列", two: "三行二列" },
          ],
          sheetName: "sheet",
          // sheetFilter: ["two", "one"],
          sheetHeader: ["第一列", "第二列"],
          // columnWidths: [20, 20],
        },
        {
          sheetData: [
            { one: "一行一列", two: "一行二列" },
            { one: "二行一列", two: "二行二列" },
          ],
        },
      ];
      try {
        let toExcel = new ExportJsonExcel(option); //new
        toExcel.saveExcel(); //保存
      } catch (error) {
        console.log(error);
      }
      
    }
    return {
      state,
      init,
      find,
      handleCurrentChange,
      formatDate,
      look,
      handleSizeChange,
      handleClose,
      exportExcel
    };
  },
};
</script>

<style lang="scss" scoped>
.main{
  background: white;
  padding: 24px;
}
*{
  font-size:14px;
}
.pagination{
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.title {
  background: white;
  padding: 24px;
  // display: flex;
  .input-width{
    width: 202px;
  }
  div {
    display: flex;
    margin-right: 25px;
    align-items: center;
    display: inline-block;
    margin-bottom: 10px;
    & > span{
      display: inline-block;
      margin-right: 12px;
    }
  }
  .end-box {
    margin-left: 10px;
  }
  margin-bottom: 12px;
}
.drawer-div{
  p{
    // font-size: 14px;
    line-height: 30px;
  }
}
::v-deep .el-drawer__body {
  overflow-y: auto !important;
}
</style>
